<template>
  <div>
    <div class="Tabs">
      <div class="Tabs-item" :class="TabsIndex==0?'Tabs-item-active':''" @click="TabsIndex=0">服务商入驻</div>
      <div class="Tabs-item" :class="TabsIndex==1?'Tabs-item-active':''" @click="TabsIndex=1">审核进展</div>
    </div>
    <div v-if="TabsIndex==0">
      <el-card class="box-card from-data">
        <el-form :model="ruleForm" ref="ruleForm" label-width="160px" class="demo-ruleForm" :rules="rules" label-position="left">
          <el-form-item label="类型" prop="providerType">
            <el-radio-group v-model="ruleForm.providerType" size="mini" @change="serviceTypeChange">
              <el-radio :label="1" border>个人</el-radio>
              <el-radio :label="2" border>企业</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 标签
          -->
          <el-form-item label="标签" prop="providerLabel" v-if="ruleForm.providerType==1">
            <el-radio-group v-model="ruleForm.providerLabel" size="mini">
              <el-radio border v-for="(item,index) in labels" :key="index" :label="item.value" :value="item.value">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="保证金" prop="deposit">
            <el-input class="inputWidth" placeholder="请输入内容" v-model="ruleForm.deposit" disabled clearable></el-input>
          </el-form-item>
          <el-form-item label="行业" prop="providerIndustry">
            <el-select v-model="ruleForm.providerIndustry" placeholder="请选择行业">
              <el-option v-for="(item, index) in serviceItems" :key="index" :label="item.itemsName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" prop="providerName">
            <el-input class="inputWidth" placeholder="2-12字符，不支持特殊字符" v-model="ruleForm.providerName" clearable></el-input>
          </el-form-item>
          <el-form-item label="所在地" prop="providerLocation">
            <el-cascader v-model="ruleForm.providerLocation" :options="city" filterable placeholder="省、市" :props="{ expandTrigger: 'hover', label: 'name', value: 'id' }"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="providerAddress">
            <el-input class="inputWidth" placeholder="请输入内容" v-model="ruleForm.providerAddress" clearable></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="userPhone">
            <el-input class="inputWidth" placeholder="请输入内容" v-model="ruleForm.userPhone" clearable></el-input>
          </el-form-item>
          <!-- 身份证 上传 -->
          <el-form-item label="身份证上传" prop="idcardImgz">
            <div class="idcardImg">
              <div>
                <ImageUpload v-model="ruleForm.idcardImgz" :isShowTip="false" :idcardImgShow="true" idcardImgTxt="人像面上传" :limit="1"></ImageUpload>
              </div>
              <div class="idcardImgf">
                <ImageUpload v-model="ruleForm.idcardImgf" :isShowTip="false" idcardImgTxt="国徽面上传" :idcardImgShow="true" :limit="1"></ImageUpload>
              </div>
              <div class="idcardImg-rule">请提交中华人民共和国居民身份证原件， 支持jpg.png</div>
            </div>
          </el-form-item>
          <!-- 营业执照 -->
          <!-- 判断条件
          如果是
          个人 营业执照 不是必填项
          企业 营业执照 必填
          -->
          <el-form-item label="营业执照" :prop="ruleForm.providerType==1?'':'licenseImg'">
            <ImageUpload v-model="ruleForm.licenseImg" :isShowTip="false"></ImageUpload>
          </el-form-item>
        </el-form>
      </el-card>
      <!-- <div class="secondCard">
      <div class="introduce">介绍</div>
      <el-card class="box-card providerContents">
        <Vhtml :Html="ruleForm.providerContents" />
      </el-card>
      </div>-->
      <div class="secondCard">
        <div class="introduce">介绍</div>
        <el-card class="box-card">
          <!--<Editor v-model="ruleForm.providerContents" />-->
          <editor v-model="ruleForm.providerContents" :min-height="192" :height="192" />
          <!-- 当 在审核中 不可 编辑 -->
          <div class="bottom" v-if="ruleForm.providerState!=1||ruleForm.providerState!=3">
            <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </div>
        </el-card>
      </div>
    </div>
    <div class v-if="TabsIndex==1">
      <div class="schedule">
        <div class="schedule-item" v-for="(item,index) in ruleForm.stateList" :key="index">
          <div class="schedule-item-type">
            <div class="examine" v-if="item.state===1"></div>
            <div class="success" v-else-if="item.state===2"></div>
            <div class="examine" v-else-if="item.state===3"></div>
            <div class="error" v-else-if="item.state===4"></div>
          </div>
          <div class="schedule-item-info">
            <div class="schedule-item-flex">
              <div class="state state-error" v-if="item.state===1">审核中</div>
              <div class="state" v-else-if="item.state===2">审核通过</div>
              <div class="state" v-else-if="item.state===3">支付保证金</div>
              <div class="state" v-else-if="item.state===4">审核不通过</div>
              <div class="day">{{formatDate(item.createTime)}}</div>
            </div>
            <div class="remark" v-if="item.remark">{{item.remark}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/Editor/index.vue";
export default {
  name: "HiringPlatform1EnterpriseSettlement",
  data() {
    return {
      TabsIndex: 0,
      introduce: "",
      StudentLabels: [],
      // 表单验证
      ruleForm: {},
      rules: {
        providerType: [
          { required: true, message: "请选择类型", trigger: "blur" },
        ],
        providerLabel: [
          { required: true, message: "请选择标签", trigger: "blur" },
        ],
        deposit: [{ required: true, message: "请填写保证金", trigger: "blur" }],
        providerIndustry: [
          { required: true, message: "请选择行业", trigger: "change" },
        ],
        providerName: [
          {
            required: true,
            message: "2-12字符，不支持特殊字符",
            trigger: "blur",
          },
          {
            min: 2,
            max: 12,
            message: "长度在 2 到 12 个字符",
            trigger: "blur",
          },
        ],
        providerLocation: [
          { required: true, message: "请选择服务商所在地", trigger: "change" },
        ],
        providerAddress: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
        userPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        // 身份证
        idcardImgz: [
          { required: true, message: "请上传身份证正面", trigger: "blur" },
        ],
        // 营业执照
        licenseImg: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
      },
    };
  },
  components: {
    // Vhtml,
    Editor,
  },
  created() {
    this.getProviderInfo();
  },
  computed: {
    serviceItems() {
      return this.$store.getters.getConfig.serviceItemsList;
    },
    city() {
      return this.$store.getters.getCity;
    },
    labels() {
      return this.$store.getters.getConfig.serviceProviderLabels
    }
  },
  methods: {
    // 获取服务商 信息
    getProviderInfo() {
      const id = this.$store.getters.getUser.id;
      this.$axios
        .get(`/app-api/hiring/service-provider-info/get/user?userId=${id}`)
        .then((res) => {
          if (res.data.code === 0) {
            const data = res.data.data;
            // 行业转换
            for (let x in this.industryOptions) {
              if (x == data.providerIndustry) {
                data.providerIndustry = this.industryOptions[x];
              }
            }
            // 身份证 转换
            data.idcardImgz = data.idcardImg.split(",")[0];
            data.idcardImgf = data.idcardImg.split(",")[1];
            data.providerLocation = [data.providerProvince, data.providerCity];
            this.ruleForm = data;
          } else {
            this.$message.warning(res.data.message);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.providerContents === "") {
            return this.$message.warning("请填写介绍信息");
          }
          if (!this.ruleForm.idcardImgf) {
            return this.$message.warning("请上传身份证反面");
          }
          // 判断行业类型
          let providerIndustry = isNaN(this.ruleForm.providerIndustry);
          if (providerIndustry) {
            for (let x in this.industryOptions) {
              if (this.industryOptions[x] == this.ruleForm.providerIndustry) {
                this.ruleForm.providerIndustry = x;
              }
            }
          }
          let params = {
            id: this.ruleForm.id,
            userId: this.$store.getters.getUser.id,
            providerType: this.ruleForm.providerType, //类型
            deposit: this.ruleForm.deposit, //保证金
            providerIndustry: this.ruleForm.providerIndustry, //行业
            providerName: this.ruleForm.providerName, //名称
            userPhone: this.ruleForm.userPhone, //联系方式
            providerAddress: this.ruleForm.providerAddress, //详细地址
            providerProvince: this.ruleForm.providerLocation[0], //省
            providerCity: this.ruleForm.providerLocation[1], //市
            providerContents: this.ruleForm.providerContents, //介绍
            licenseImg: this.ruleForm.licenseImg,
            idcardImg: [
              this.ruleForm.idcardImgz,
              this.ruleForm.idcardImgf,
            ].join(","),
            providerLabel: this.resetForm.providerLabel,
          };
          this.$axios
            .put("/app-api/hiring/service-provider-info/update", params)

            .then((res) => {
              if (res.data.code === 0) {
                this.$message.success("更新成功");
                this.getProviderInfo();
              }
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          this.$nextTick(() => {
            this.$message.warning("请填写完信息,在提交!");
            let isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: "center",
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: "smooth",
            });
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.ruleForm.providerContents = "";
      this.ruleForm.idcardImgf = "";
      this.ruleForm.providerIndustry = "";
      this.$refs[formName].resetFields();
    },
    serviceTypeChange(value) {
      this.ruleForm.deposit = value == 1 ? 1000 : 2000;
    },
  },
};
</script>

<style lang="less" scoped>
.Tabs {
  border-radius: 8px;
  height: 88px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  .Tabs-item {
    color: #999999;
    font-size: 20px;
    margin-right: 30px;
    position: relative;
    cursor: pointer;
  }
  .Tabs-item-active {
    color: #f41e1e;
    &::after {
      content: "";
      position: absolute;
      bottom: -12px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #f41e1e;
    }
  }
}
.schedule {
  border-radius: 8px;
  background: #ffffff;
  margin-top: 20px;
  .schedule-item {
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    .schedule-item-type {
      display: flex;
      align-items: center;
      margin-right: 18px;
      div {
        width: 16px;
        height: 16px;
        box-sizing: border-box;
        border-radius: 50%;
        position: relative;
      }
      .examine {
        border: 1px solid #999999;
        background: #e9e9e9;
      }
      .error {
        border: 1px solid #f41e1e;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 8px;
          height: 8px;
          background: #f41e1e;
          border-radius: 50%;
        }
      }
      .success {
        border: 1px solid #209f85;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 8px;
          height: 8px;
          background: #209f85;
          border-radius: 50%;
        }
      }
    }
    .schedule-item-info {
      padding: 20px 0;
      border-bottom: 1px solid #e9e9e9;
      width: calc(100% - 60px);
      &:last-of-type {
        border-bottom: none !important;
      }
      .schedule-item-flex {
        display: flex;
        align-items: center;
      }
      .state {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        margin-right: 40px;
      }
      .state-error {
        color: #999999 !important;
      }
      .day {
        color: #999999;
        font-size: 18px;
      }
      .remark {
        margin-top: 12px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.providerContents {
  border-radius: 8px;
  img {
    width: 100%;
  }
}
.from-data {
  border-radius: 8px;
  margin-top: 20px;
  .idcardImg {
    display: flex;
    .idcardImg-rule {
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      font-size: 14px;
      margin-left: 20px;
      width: 257px;
    }
    .idcardImgf {
      margin-left: 20px;
    }
  }
}
//
.inputWidth {
  width: 217px;
}

.secondCard {
  margin-top: 20px;

  .introduce {
    margin: 20px;
    text-align: left;
    font-size: 24px;
    font-weight: 400;
    color: #000;
    line-height: 28px;
  }

  .bottom {
    margin: 20px auto;
    text-align: center;
  }
}
</style>
